@use "../mixins/mixins";

$tablet:768px;
$mobile:320px;
$desktop:1200px;

@mixin mobile {
    @media (min-width: $mobile) and (max-width:767px){
        @content
    } 
};

@mixin tablet {
    @media (min-width: $tablet) and (max-width:1199px){
        @content
    } 
};

@mixin desktop {
    @media(min-width: $desktop) and (max-width:2400px){
        @content
    } 
};

.nav{
    display: flex;
    background-color: #00B6E7;
    // justify-content: center;
    border-bottom-style: solid ;
    border-bottom-width:3px ;
    border-bottom-color: #F2652E;
    align-items: center;
    //padding: 0%; 
    margin: 0%;
    &__left{
        //display: inline-block;
        display: inline-flex;
        &--button{
            display: inline-block;
            text-decoration: solid;
            opacity: .9;
            background-color: #F2652E;
            padding: 3% 30%;
        }
    }
    &__logo{
        display: flex;
        align-self: center;
        margin: auto;
        //justify-content: center;
        @include mobile{
            justify-content: space-around;
            margin:0 50% ;
        }
    }
}
*img.logo{
    
    @include mobile{
        padding-right: 100% ;
        width: 100px;
       
    }
}

.logo{
    width:100%;
    height: 3rem;
    // @include mobile{
    //     width: 3em;
    // }
    
}
