$tablet:768px;
$mobile:320px;
$desktop:1200px;

@mixin mobile {
    @media (min-width: $mobile) and (max-width:767px){
        @content
    } 
};

@mixin tablet {
    @media (min-width: $tablet) and (max-width:1199px){
        @content
    } 
};

@mixin desktop {
    @media(min-width: $desktop) and (max-width:2400px){
        @content
    } 
};
*{
    margin: 0%;
    font-family: "Source Sans Pro" ,sans-serif ;
    
}

.Login{
    display: flex;
    border-style: solid;
    border-radius: 8px;
    border-color:#00B6E7;
    padding: 1.5% 1%;
    &__Holder{
        //margin: 0 50%;
        
        justify-content: center;
        
       //align-content: space-around;
       &--Date{
        margin: auto;
       }
       &--Button{
        text-decoration: solid;
       
       }
    }
}

.ChkHolder{
    display: flex;
    justify-content: center;
    border:5px;
    border-radius: 10%;
}

// .ChkHolder{
//     display: flex;
//     justify-content: center;
// }

.card-title{
    //margin: 0 50%;
    padding: 0 50%;
    content: 0 25%;
}
*span{
    display: flex;
    justify-content: space-around;
}

*button{
    display: flex;
    margin: 15%;
    opacity: .9;
    background-color: #F2652E;
    padding: 4%;
    
}