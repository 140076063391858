@use "../mixins/mixins";

$tablet:768px;
$mobile:320px;
$desktop:1200px;

@mixin mobile {
    @media (min-width: $mobile) and (max-width:767px){
        @content
    } 
};

@mixin tablet {
    @media (min-width: $tablet) and (max-width:1199px){
        @content
    } 
};

@mixin desktop {
    @media(min-width: $desktop) and (max-width:2400px){
        @content
    } 
};

.nav{
    display: flex;
    // justify-content: center;
    align-items: center;
    padding: 0%; 
    &__left{
        //margin-right: 27%;
        &--button{
            display: inline-block;
            text-decoration: solid;
            opacity: .9;
            background-color: #F2652E;
            padding: 3% 30%;
        }
    }
    &__logo{
        align-self: center;
        margin: 0 32%;
        @include mobile{
            margin: 0 20%;
        }
        //justify-content: center;
    }
}

.logo{
    width:100%;
    height: 3rem;
    
}