$tablet:768px;
$mobile:320px;
$desktop:1200px;

@mixin mobile {
    @media (min-width: $mobile) and (max-width:767px){
        @content
    } 
};

@mixin tablet {
    @media (min-width: $tablet) and (max-width:1199px){
        @content
    } 
};

@mixin desktop {
    @media(min-width: $desktop) and (max-width:2400px){
        @content
    } 
};

.guestForm{
    display: flex;
    justify-content: center;
    
    //padding: 0 5%;
  
    &__container{
        display: flex;
        margin: 0 50%;
        padding: 1.5% 3%;
        justify-content: center;
        align-content: space-around;
        border-radius: 8px;
        border-color:#00B6E7;
        //align-self: self-start;
        
        border-style: solid;
        &--inputs{
            flex-grow: 1;
            justify-content: space-around;
            
        }
        &--button{
            display: flex;
            justify-content: center;
        }
    }
}

*label{
    display: flex;
    justify-content: space-between;
    font-weight: 500;
}

*input{
    display: flex;
    justify-content: space-around;
}

*span{
    display: flex;
    justify-content: space-around;
}
*p{
    font-weight: bold;
    margin: auto;
}
